var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: {
        title: _vm.title,
        width: 800,
        ok: false,
        visible: _vm.visible,
        confirmLoading: _vm.confirmLoading,
        okButtonProps: { props: { disabled: _vm.disableSubmit } },
        cancelText: "关闭"
      },
      on: { ok: _vm.handleOk, cancel: _vm.handleCancel }
    },
    [
      _c(
        "a-spin",
        { attrs: { spinning: _vm.confirmLoading } },
        [
          _c(
            "a-form-model",
            {
              ref: "form",
              attrs: { model: _vm.model, rules: _vm.validatorRules }
            },
            [
              _c(
                "a-form-model-item",
                {
                  attrs: {
                    labelCol: _vm.labelCol,
                    wrapperCol: _vm.wrapperCol,
                    label: "机构名称",
                    prop: "departName",
                    hidden: false,
                    hasFeedback: ""
                  }
                },
                [
                  _c("a-input", {
                    attrs: {
                      id: "departName",
                      placeholder: "请输入机构/部门名称"
                    },
                    model: {
                      value: _vm.model.departName,
                      callback: function($$v) {
                        _vm.$set(_vm.model, "departName", $$v)
                      },
                      expression: "model.departName"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  attrs: {
                    labelCol: _vm.labelCol,
                    wrapperCol: _vm.wrapperCol,
                    hidden: _vm.seen,
                    label: "上级部门",
                    hasFeedback: ""
                  }
                },
                [
                  _c("a-tree-select", {
                    staticStyle: { width: "100%" },
                    attrs: {
                      dropdownStyle: { maxHeight: "200px", overflow: "auto" },
                      treeData: _vm.departTree,
                      placeholder: "请选择上级部门",
                      disabled: _vm.condition
                    },
                    model: {
                      value: _vm.model.parentId,
                      callback: function($$v) {
                        _vm.$set(_vm.model, "parentId", $$v)
                      },
                      expression: "model.parentId"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  attrs: {
                    labelCol: _vm.labelCol,
                    wrapperCol: _vm.wrapperCol,
                    label: "机构类型"
                  }
                },
                [
                  _vm.seen
                    ? [
                        _c(
                          "a-radio-group",
                          {
                            attrs: { placeholder: "请选择机构类型" },
                            model: {
                              value: _vm.model.orgCategory,
                              callback: function($$v) {
                                _vm.$set(_vm.model, "orgCategory", $$v)
                              },
                              expression: "model.orgCategory"
                            }
                          },
                          [
                            _c("a-radio", { attrs: { value: "1" } }, [
                              _vm._v("\n              公司\n            ")
                            ])
                          ],
                          1
                        )
                      ]
                    : [
                        _c(
                          "a-radio-group",
                          {
                            attrs: { placeholder: "请选择机构类型" },
                            model: {
                              value: _vm.model.orgCategory,
                              callback: function($$v) {
                                _vm.$set(_vm.model, "orgCategory", $$v)
                              },
                              expression: "model.orgCategory"
                            }
                          },
                          [
                            _c("a-radio", { attrs: { value: "2" } }, [
                              _vm._v("\n              部门\n            ")
                            ]),
                            _c("a-radio", { attrs: { value: "3" } }, [
                              _vm._v("\n              岗位\n            ")
                            ])
                          ],
                          1
                        )
                      ]
                ],
                2
              ),
              _c(
                "a-form-model-item",
                {
                  attrs: {
                    labelCol: _vm.labelCol,
                    wrapperCol: _vm.wrapperCol,
                    prop: "mobile",
                    label: "电话"
                  }
                },
                [
                  _c("a-input", {
                    attrs: { placeholder: "请输入电话" },
                    model: {
                      value: _vm.model.mobile,
                      callback: function($$v) {
                        _vm.$set(_vm.model, "mobile", $$v)
                      },
                      expression: "model.mobile"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  attrs: {
                    labelCol: _vm.labelCol,
                    wrapperCol: _vm.wrapperCol,
                    label: "传真"
                  }
                },
                [
                  _c("a-input", {
                    attrs: { placeholder: "请输入传真" },
                    model: {
                      value: _vm.model.fax,
                      callback: function($$v) {
                        _vm.$set(_vm.model, "fax", $$v)
                      },
                      expression: "model.fax"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  attrs: {
                    labelCol: _vm.labelCol,
                    wrapperCol: _vm.wrapperCol,
                    label: "地址"
                  }
                },
                [
                  _c("a-input", {
                    attrs: { placeholder: "请输入地址" },
                    model: {
                      value: _vm.model.address,
                      callback: function($$v) {
                        _vm.$set(_vm.model, "address", $$v)
                      },
                      expression: "model.address"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  attrs: {
                    labelCol: _vm.labelCol,
                    wrapperCol: _vm.wrapperCol,
                    label: "排序"
                  }
                },
                [
                  _c("a-input-number", {
                    model: {
                      value: _vm.model.departOrder,
                      callback: function($$v) {
                        _vm.$set(_vm.model, "departOrder", $$v)
                      },
                      expression: "model.departOrder"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  attrs: {
                    labelCol: _vm.labelCol,
                    wrapperCol: _vm.wrapperCol,
                    label: "备注"
                  }
                },
                [
                  _c("a-textarea", {
                    attrs: { placeholder: "请输入备注" },
                    model: {
                      value: _vm.model.memo,
                      callback: function($$v) {
                        _vm.$set(_vm.model, "memo", $$v)
                      },
                      expression: "model.memo"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }