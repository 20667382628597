var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    { class: { abcdefg: true }, attrs: { bordered: false } },
    [
      _c(
        "div",
        { staticClass: "no-print", staticStyle: { "text-align": "right" } },
        [
          _c(
            "a-button",
            {
              directives: [
                {
                  name: "print",
                  rawName: "v-print",
                  value: "#printContent",
                  expression: "'#printContent'"
                }
              ],
              attrs: { ghost: "", type: "primary" }
            },
            [_vm._v("打印")]
          )
        ],
        1
      ),
      _c(
        "section",
        { ref: "print", staticClass: "abcdefg", attrs: { id: "printContent" } },
        [
          _c("div", { staticStyle: { "text-align": "center" } }, [
            _c(
              "p",
              { staticStyle: { "font-size": "24px", "font-weight": "800" } },
              [_vm._v("打印测试表单")]
            )
          ]),
          _c("a-col", { attrs: { md: 24, sm: 24 } }, [
            _c(
              "div",
              {
                staticClass: "sign",
                staticStyle: { "text-align": "left", height: "inherit" }
              },
              [
                _c(
                  "a-col",
                  { attrs: { span: 24 } },
                  [
                    _c("span", [_vm._v("\n          打印人员:\n        ")]),
                    _c("a-input", {
                      staticStyle: { width: "30%" },
                      model: {
                        value: _vm.printer,
                        callback: function($$v) {
                          _vm.printer = $$v
                        },
                        expression: "printer"
                      }
                    }),
                    _c("span", { staticStyle: { "margin-left": "12.5%" } }, [
                      _vm._v("打印日期:")
                    ]),
                    _c("a-input", {
                      staticStyle: { width: "30%" },
                      model: {
                        value: _vm.printTime,
                        callback: function($$v) {
                          _vm.printTime = $$v
                        },
                        expression: "printTime"
                      }
                    })
                  ],
                  1
                ),
                _c("a-col", { attrs: { span: 24 } }),
                _c(
                  "a-col",
                  {
                    staticStyle: { "margin-top": "20px" },
                    attrs: { span: 24 }
                  },
                  [
                    _c("span", [_vm._v("打印内容:")]),
                    _c("a-input", {
                      staticStyle: { width: "80%" },
                      model: {
                        value: _vm.printContent,
                        callback: function($$v) {
                          _vm.printContent = $$v
                        },
                        expression: "printContent"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "a-col",
                  {
                    staticStyle: { "margin-top": "20px" },
                    attrs: { span: 24 }
                  },
                  [
                    _c("span", [_vm._v("打印目的:")]),
                    _c("a-input", {
                      staticStyle: { width: "80%" },
                      model: {
                        value: _vm.printReason,
                        callback: function($$v) {
                          _vm.printReason = $$v
                        },
                        expression: "printReason"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "a-col",
                  {
                    staticStyle: { "margin-top": "20px" },
                    attrs: { span: 24 }
                  },
                  [
                    _c("span", [_vm._v("打印图片:")]),
                    _c("br"),
                    _c(
                      "a-upload",
                      {
                        attrs: {
                          action: "/jsonplaceholder.typicode.com/posts/",
                          listType: "picture-card",
                          fileList: _vm.fileList
                        },
                        on: {
                          preview: _vm.handlePreview,
                          change: _vm.handleChange
                        }
                      },
                      [
                        _vm.fileList.length < 3
                          ? _c(
                              "div",
                              [
                                _c("a-icon", { attrs: { type: "plus" } }),
                                _c("div", { staticClass: "ant-upload-text" }, [
                                  _vm._v("Upload")
                                ])
                              ],
                              1
                            )
                          : _vm._e()
                      ]
                    ),
                    _c(
                      "a-modal",
                      {
                        attrs: { visible: _vm.previewVisible, footer: null },
                        on: { cancel: _vm.handleCancel }
                      },
                      [
                        _c("img", {
                          staticStyle: { width: "100%" },
                          attrs: { alt: "example", src: _vm.previewImage }
                        })
                      ]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ])
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }