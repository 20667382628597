var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: {
        title: _vm.title,
        width: 800,
        visible: _vm.visible,
        maskClosable: false,
        confirmLoading: _vm.confirmLoading,
        cancelText: "关闭"
      },
      on: { ok: _vm.handleOk, cancel: _vm.handleCancel }
    },
    [
      _c(
        "a-spin",
        { attrs: { spinning: _vm.confirmLoading } },
        [
          _c(
            "a-form-model",
            {
              ref: "form",
              attrs: { model: _vm.model, rules: _vm.validatorRules }
            },
            [
              _c(
                "a-form-model-item",
                {
                  attrs: {
                    labelCol: _vm.labelCol,
                    wrapperCol: _vm.wrapperCol,
                    prop: "code",
                    required: "",
                    label: "职务编码"
                  }
                },
                [
                  _c("a-input", {
                    attrs: {
                      placeholder: "请输入职务编码",
                      "read-only": _vm.readOnly
                    },
                    model: {
                      value: _vm.model.code,
                      callback: function($$v) {
                        _vm.$set(_vm.model, "code", $$v)
                      },
                      expression: "model.code"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  attrs: {
                    labelCol: _vm.labelCol,
                    wrapperCol: _vm.wrapperCol,
                    prop: "name",
                    required: "",
                    label: "职务名称"
                  }
                },
                [
                  _c("a-input", {
                    attrs: { placeholder: "请输入职务名称" },
                    model: {
                      value: _vm.model.name,
                      callback: function($$v) {
                        _vm.$set(_vm.model, "name", $$v)
                      },
                      expression: "model.name"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  attrs: {
                    labelCol: _vm.labelCol,
                    wrapperCol: _vm.wrapperCol,
                    prop: "postRank",
                    required: "",
                    label: "职级"
                  }
                },
                [
                  _c("j-dict-select-tag", {
                    attrs: {
                      placeholder: "请选择职级",
                      dictCode: "position_rank"
                    },
                    model: {
                      value: _vm.model.postRank,
                      callback: function($$v) {
                        _vm.$set(_vm.model, "postRank", $$v)
                      },
                      expression: "model.postRank"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }